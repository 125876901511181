import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import { Heading2CSS, MainBodyCSS } from '../styles/typography'
import { breakpoints } from '../styles/breakpoints'

import { formatRichText } from '../helpers/contentful'
import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'

import Wrapper from '../components/wrapper/wrapper'

const H2 = styled.h2`
  ${Heading2CSS}
  margin-bottom: 24px;
  text-align: center;

  @media ${breakpoints.laptop} {
    margin-bottom: 48px;
  }
`

const Content = styled.div`
  margin: auto;
  max-width: 600px;

  @media ${breakpoints.wide} {
    max-width: 720px;
  }
`

const Body = styled.div`
  p {
    ${MainBodyCSS}
    text-align: center;
  }
`

const seoDescription = `
  Born and raised in Tokyo, Japan, Keiichiro’s dream was always to
  become a hair stylist and to this end he began training full time when
  he was just 17. After learning and developing his trade in his home
  town he moved to London to further his passion and career as a session
  hair stylist. His editorial work has since appeared across the globe in
  publications such as Vogue, Harpers Bazaar, Tatler, Sunday Times, Tush,
  and GQ.
`

export default function Profile({ data }) {
  const profile = data.allContentfulProfile.edges[0].node

  return (
    <Layout>
      <SEO
        title={profile.title}
        description={seoDescription} />

      <Wrapper>
        <Content>
          <H2>{profile.title}</H2>
          <Body>
            {formatRichText(profile.biography.json)}
          </Body>
        </Content>
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulProfile {
      edges {
        node {
          title
          biography {
            json
          }
        }
      }
    }
  }
`
